import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BreakpointObserver, Breakpoints, LayoutModule } from '@angular/cdk/layout';
import { map } from 'rxjs/internal/operators/map';

const enterTransition = transition(':enter', [
  style({ height: '0vh' }),
  animate('3s ease-in', style({ height: 'auto' }))
]);
const easeOut = trigger('easeOut', [enterTransition]);
@Component({
  selector: 'app-currency-calculator',
  standalone: true,
  imports: [CommonModule, DatePipe, ReactiveFormsModule, LayoutModule],
  providers: [],
  templateUrl: './currency-calculator.component.html',
  styleUrl: './currency-calculator.component.scss',
  animations: [easeOut]
})
export class CurrencyCalculatorComponent implements OnInit {
  @Input() lastUpdated: Date = new Date();        // accept date input
  @Input() pesoUsd: number = 850.0;
  @Input() hasError: boolean = false;

  @Output() activateRankingChange = new EventEmitter<boolean>();

  currencyControl = new FormControl('');

  public amount: number = 1;
  public activateRanking: boolean = false;
  public isValidNumber: boolean = false;
  public disclaimer = 'Estos valores son referenciales, si quieres saber el valor real ingresa al sitio web de las otras entidades financieras*';
  private otherCardsPercentage = 0.03;
  private otherBanksPercentage = 0.04;
  public textMarketingCards = "Valor del dólar entre un 3% a 4% más alto";

  private isTabletOrMobile = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  public getPeso(): number {
    return this.pesoUsd * this.amount;
  }

  public getPesoOtherCards(): number {
    return this.pesoUsd + (this.pesoUsd * this.otherCardsPercentage);
  }

  public getPesoOtherBanks(): number {
    return this.pesoUsd + (this.pesoUsd * this.otherBanksPercentage);
  }

  public getValueOtherCards(): number {
    return (this.pesoUsd + (this.pesoUsd * this.otherCardsPercentage)) * this.amount;
  }

  public getValueOtherBanks(): number {
    return (this.pesoUsd + (this.pesoUsd * this.otherBanksPercentage)) * this.amount;
  }

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.currencyControl.valueChanges.subscribe(res => {
      this.isValidNumber = this.isValidPositiveDecimal(res || '');
    });
  }

  calculateExchangeRate() {
    if (!this.activateRanking) {
      this.activateRanking = !this.activateRanking;
      this.activateRankingChange.emit(this.activateRanking);
    }
    if (this.isValidNumber) {
      if (typeof this.currencyControl.value === 'string') {
        this.amount = parseFloat(this.currencyControl.value.replace(',', '.'));
        console.log(this.amount * this.pesoUsd);
      }
      this.isTabletOrMobile.subscribe((res) => {
        console.log(res);
        setTimeout(() => {
          if (res) { this.scrollToElement(); }
        });
      });
    }
  }

  /**
   * Validate a positive number with any decimals.
   * @param value 
   * @returns 
   */
  isValidPositiveDecimal(value: string): boolean {
    // const regex = /^\d+(\.\d{1,2})?$/;
    // const regex = /^[0-9]+(,[0-9]{1,2})?$/;
    const regex = /^[0-9]+(,[0-9]+)?$/;
    const parsedValue = parseFloat(value.replace(',', '.'));
    return regex.test(value) && !isNaN(parsedValue);
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Enter' && this.isValidNumber && !this.hasError) { this.calculateExchangeRate(); }
  }

  scrollToElement(): void {
    const element = document.getElementById('item-list-1');
    // this.renderer.selectRootElement(element).scrollIntoView({ behavior: "smooth", });
    element?.scrollIntoView({ behavior: "smooth", });
  }
}

<style>
  :host {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<main class="main">
  <app-currency-calculator (activateRankingChange)="onActivateRankingChange($event)" [lastUpdated]="date"
    [pesoUsd]="currency" [hasError]="hasError"></app-currency-calculator>
  <div class="content">
    <div class="row-container nw sb-9 center">
      <img src="assets/images/phone.png" alt="phone" [ngClass]="{'img-phone': true, 'mt-1': activateRanking}">
      <div class="column-container w-60">
        <h1 class="title">Tarjeta Prepago Copec Pay Mastercard </h1>
        <p class="subtitle">Compra en Chile y en el extranjero cuando quieras. </p>
        <div class="row-container g-8 no-col">
          <img src="assets/svg/money-icon.svg" alt="money-icon">
          <p class="marketing-text">Sin comisiones ni costos de mantención</p>
        </div>
        <div class="row-container g-8 center-x no-col">
          <img src="assets/svg/globe-icon.svg" alt="globe-icon">
          <p class="marketing-text">Compra online y en el mundo</p>
        </div>
        <div class="row-container g-8 center-x no-col">
          <img src="assets/svg/ok-hand.svg" alt="ok-hand">
          <p class="marketing-text">Sin costo adicional por tipo de cambio</p>
        </div>
        <div class="row-container g-8 center-x no-col">
          <img src="assets/svg/call-person.svg" alt="call-person">
          <p class="marketing-text">Atención 24/7</p>
        </div>
      </div>
    </div>
  </div>
  <div class="column-container center g-1 p20-600">
    <img src="assets/images/brands.png" alt="brands" class="brands-container">
    <h1 class="title center">Copec Pay la mejor alternativa. Ahorra, sin preocuparte de cobros adicionales.</h1>
    <p class="subtitle center p-1">Aprovecha el mejor tipo de cambio de dólar al realizar tus compras en comercios
      internacionales
      favoritos con la tarjeta digital Copec Pay y ahorra en cada transacción.</p>
  </div>
  <div class="row-container center-y g-3 my-4">
    <img src="assets/images/gplay.png" alt="gplay" height="38px">
    <img src="assets/images/appstore.png" alt="appstore" height="38px">
  </div>
</main>
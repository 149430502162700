import { Component, Input } from '@angular/core';
import { CurrencyCalculatorComponent } from '../currency-calculator/currency-calculator.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CurrencyCalculatorComponent, CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  @Input() hasError: boolean = false;       // accept date input
  @Input() date: Date = new Date();        // accept date input
  @Input() currency: number = 0;

  title = 'Comparador Dolar';
  public activateRanking = false;
  public disclaimer = 'Estos valores son referenciales, si quieres saber el valor real ingresa al sitio web de las otras entidades financieras*';

  constructor() { }

  onActivateRankingChange(activateRanking: boolean) {
    // update the value when the child component emits the event
    this.activateRanking = activateRanking;
  }
}

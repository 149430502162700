import { Component, OnInit } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import localeCL from '@angular/common/locales/es-CL';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CurrencyService } from './services/currency.service';
import { HomeComponent } from './home/home.component';

registerLocaleData(localeCL, 'cl');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent, HomeComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'copec-dollar-viewer';
  public isLoading = true;
  public pesoUsd: number = 850;
  public lastUpdated: Date = new Date();
  public hasError: boolean = false;

  constructor(private _currencyService: CurrencyService) { }

  ngOnInit(): void {
    this._currencyService.getCurrency().subscribe({
      next: (res) => {
        // TODO: en cada caso de error enviar a vista BaseError
        if (res && res.status_code == "200") {
          this.pesoUsd = res.data?.valorDolar || 0;
          if (res.data?.ultimaFechaActualizacion) {
            const date = res.data?.ultimaFechaActualizacion?.toString().substring(0, 10);
            const time = res.data?.ultimaFechaActualizacion?.toString().substring(11);
            const formattedDate = new Date(date + 'T' + time);
            this.lastUpdated = formattedDate || Date.now();
          } else {
            console.error("Error No Date: ", res);
            this.hasError = true;
          }
        } else {
          console.error("Status Code Error: ", res);
          this.hasError = true;
        }
        this.isLoading = false;
      },
      error: (err) => {
        console.error('CurrencyService Error: ', err);
        this.isLoading = false;
        this.hasError = true;
      }
    });
  }
}

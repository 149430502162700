<footer class="site-footer">
  <div class="footer-section">
    <a href="https://www.appcopec.cl/copecpay/" target="_blank" style="display: block;">
      <img class="logo" src="assets/svg/logo-negativo.svg" alt="Company Logo">
    </a>
    <a href="tel:800200354" style="width: 50%; color: #fff;display: block; margin-bottom: 10px; text-decoration: none;">
      <p style="vertical-align: middle; font-weight: bold; font-size: 16px;"> {{contactNumber}} </p>
    </a>
    <span style="font-size: 15px; line-height: normal; font-weight: 400; opacity: 0.5;"> Atención 24/7</span>
    <div>
      <p style="font-size: 15px; line-height: normal; font-weight: 400; opacity: 0.5;"> {{disclaimerText}} </p>
    </div>
  </div>
  <div style="display: flex; flex-direction: column;">
    <h6 style="font-size: 18px;font-weight: 600;margin-bottom: 20px">Enlaces de interés</h6>
    <div *ngFor="let link of links">
      <a [href]="link.url" target="_blank"
        style="font-size: 18px; text-decoration: none; display: block;line-height: 1.4;font-weight: 400; color: #00ff7e;margin-bottom: 10px;">
        {{link.label}}
      </a>
    </div>
  </div>
  <div style="display: flex; flex-direction: column;">
    <h6 style="font-size: 18px;font-weight: 600;margin-bottom: 20px">Ayuda</h6>
    <div *ngFor="let link of helpLinks">
      <a [href]="link.url" target="_blank"
        style="font-size: 18px; text-decoration: none; display: block;line-height: 1.4;font-weight: 400; color: #00ff7e;margin-bottom: 10px;">
        {{link.label}}
      </a>
    </div>
  </div>
  <div class="footer-section">
    <h6 style="font-size: 18px;font-weight: 600;margin-bottom: 20px">Descarga app Copec y disfruta de todos nuestros
      beneficios
    </h6>
    <div class="store-links">
      <a>
        <img src="https://www.appcopec.cl/wp-content/themes/chris/img/billetera/stores/google-play.png" alt="googleplay">
      </a>
      <a>
        <img src="https://www.appcopec.cl/wp-content/themes/chris/img/billetera/stores/app-store.png" alt="appstore">
      </a>
    </div>
    <p style="font-size: 15px; line-height: normal; font-weight: 400; opacity: 0.5;">{{copyrightText}}</p>
  </div>
</footer>
<div [ngClass]="{'wrapper': true, 'expand-height': activateRanking }">
  <h1 class="title2"> Cámbiate a <span> Copec Pay </span> por un mejor valor de dólar </h1>
  <div class="content2">
    <div class="row-container">
      <div class="input-container">
        <p class="text-normal-500" style="margin:8px 0;"> Ingrese una cantidad </p>
        <div class="currency-container">
          <img src="assets/svg/dollar-sign.svg" />
          <input class="currency-input" type="text" placeholder="0,00" [formControl]="currencyControl" required
            minlength="1" />
        </div>
      </div>
      <div class="flags-container">
        <div class="flag-container">
          <p class="text-normal-500" style="margin:8px 0;"> De </p>
          <div class="flag-btn-container">
            <button class="flag-btn">
              <img src="assets/svg/usa-flag.svg" alt="usa-flag">
            </button>
            <span class="text-normal-500"> USD </span>
          </div>
        </div>
        <div class="arrow-container">
          <img src="assets/svg/arrow-right.svg" alt="arrow-right">
        </div>
        <div class="flag-container">
          <p class="text-normal-500" style="margin:8px 0;"> A </p>
          <div class="flag-btn-container">
            <button class="flag-btn">
              <img src="assets/svg/cl-flag.svg" alt="cl-flag">
            </button>
            <span class="text-normal-500"> CLP </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row-container">
      <div class="date-container">
        <h3 *ngIf="!hasError"> $1,00USD =
          <span style="color: #002DF9">
            {{ pesoUsd | currency: 'CLP': "$": '.2-2': 'cl' }}
          </span>
          CLP
        </h3>
        <span class="date-text" *ngIf="!hasError"> Última actualización
          {{ lastUpdated | date: 'dd/MM/y HH:mm' }} hrs
        </span>
        <span class="date-text" *ngIf="hasError"> No fue posible actualizar la información. Intenta más tarde. </span>
      </div>
      <div class="expanded-btn mt-1">
        <button [ngClass]="{'submit-btn': (!isValidNumber || hasError), 'active-btn': (isValidNumber && !hasError)}"
          (click)="calculateExchangeRate()" [disabled]="(!isValidNumber || hasError)" type="submit"
          (keydown)="calculateExchangeRate()">
          <span class="submit-text">Calcular monto</span>
        </button>
      </div>
    </div>
  </div>

  <div id="item-list-1" class="item-list-container2" *ngIf="activateRanking">
    <div class="item">
      <img class="item-image" src="assets/svg/copec-pay-rounded.svg" alt="cp-rounded">
      <div class="item-description-container">
        <div class="item-description">
          <h3>Copec Pay</h3>
          <span class="green-badge">Mejor opción</span>
        </div>
        <div class="marketing-container">
          <p class="marketing-text">
            <img src="assets/svg/check.svg" alt="check"> Dólar más conveniente
          </p>
          <p class="marketing-text"><img src="assets/svg/check.svg" alt="check"> Sin comisiones ni mantenciones</p>
        </div>
      </div>
      <div style="width: 58px;"></div>
      <div class="peso-container">
        <div class="main-price">{{ getPeso() | currency: 'CLP': "symbol-narrow": '.0': 'cl' }}</div>
        <span class="secondary-price">Valor dólar: {{pesoUsd | currency: 'CLP': "symbol-narrow": '.2-2': 'cl'}}</span>
      </div>
    </div>
    <div class="item">
      <img class="item-image" src="assets/svg/wallet.svg" alt="cp-rounded">
      <div class="item-description-container">
        <div class="item-description">
          <h3>Otras tarjetas de prepago</h3>
        </div>
        <div class="marketing-container">
          <p class="marketing-text"><img src="assets/svg/cancel.svg" alt="check"> {{ textMarketingCards }}</p>
        </div>
      </div>
      <div style="width: 190px;"></div>
      <div class="peso-container">
        <div class="competition-price">{{ getValueOtherCards() | currency: 'CLP': "symbol-narrow": '.0': 'cl' }}</div>
        <span class="secondary-price">Valor dólar: {{ getPesoOtherCards() | currency: 'CLP': "symbol-narrow":
          '.2-2': 'cl'}}</span>
      </div>
    </div>
    <div class="item">
      <img class="item-image" src="assets/svg/bank-icon.svg" alt="cp-rounded">
      <div style="display: flex; flex-direction: column;">
        <div class="item-description">
          <h3>Otros bancos</h3>
        </div>
        <div class="marketing-container">
          <p class="marketing-text"><img src="assets/svg/cancel.svg" alt="check"> Valor dólar bancario + comisión
            internacional</p>
        </div>
      </div>
      <div style="width: 190px;"></div>
      <div class="peso-container">
        <div class="competition-price">{{ getValueOtherBanks() | currency: 'CLP': "symbol-narrow": '.0': 'cl' }}</div>
        <span class="secondary-price">Valor dólar: {{ getPesoOtherBanks() | currency: 'CLP': "symbol-narrow":
          '.2-2': 'cl'}}</span>
      </div>
    </div>
  </div>

  <p class="disclaimer2" *ngIf="activateRanking"> {{disclaimer}} </p>
</div>
import { NgFor } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgFor],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public contactNumber: string = '800 200 354';
  public disclaimerText: string = 'Infórmese sobre las entidades autorizadas para emitir Tarjetas de Pago en el país, quienes se encuentran inscritas en los Registros de Emisores de Tarjetas que lleva la CMF, en www.cmfchile.cl';
  public copyrightText: string = '@Copyright 2022 Copec S. A. Todos los derechos reservados'
  public links = [
    {
      url: 'https://www.appcopec.cl/wp-content/uploads/2023/04/Terminos-y-Condiciones-App-Copec.pdf',
      label: 'T&Cs Copec Pay'
    },
    {
      url: 'https://www.appcopec.cl/wp-content/uploads/2023/04/Politica-Privacidad-App-Copec.pdf',
      label: 'Politica de privacidad'
    },
    {
      url: 'https://www.appcopec.cl/estados-financieros/',
      label: 'Estados financieros'
    },
    {
      url: 'https://www.appcopec.cl/ley-de-fraude/',
      label: 'Ley de fraude'
    }
  ];
  public helpLinks = [
    {
      url: 'https://ww2.copec.cl/centro-de-ayuda/preguntas-frecuentes/personas/copec_pay',
      label: 'Centro de ayuda'
    },
    {
      url: 'https://ww2.copec.cl/centro-de-ayuda/preguntas-frecuentes/personas/copec_pay/que-hago-si-me-roban-o-pierdo-mi-celular/kA06Q000000g4wYSAQ',
      label: 'Canal de emergencias'
    },
    {
      url: 'https://www.appcopec.cl/wp-content/uploads/2023/03/codigo-de-etica-copec-pay.pdf',
      label: 'Código de ética'
    },
    {
      url: 'https://www.appcopec.cl/canal-de-reporte-etico/',
      label: 'Canal de reporte ético'
    }
  ]
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-base-error',
  standalone: true,
  imports: [],
  templateUrl: './base-error.component.html',
  styleUrl: './base-error.component.scss'
})
export class BaseErrorComponent {

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseErrorComponent } from './shared/base-error/base-error.component';
import { HomeComponent } from './home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '**',
    component: BaseErrorComponent,
    title: 'Copec Pay - Página no encontrada',
    data: {
      isError: true,
      titleText: 'Página no encontrada',
      subtitleText: 'La página que buscas no existe.',
      showButton: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }



import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrencyResponse } from '../models/currency-response.model';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {

  constructor(private http: HttpClient) { }

  private amb = window.location.host.substring(0, 2); // Ambiente segun URL
  private api = 'comparador/api';

  private getApiKey(): string {
    return this.amb === 'de' ? 'YE0mojvMzQ5Bs5I36MSZ61HyUbnzlr4n2A3Au6H6' : this.amb === 'qa' ? '7O0UFw9rpH28NdafI2jQn5caxire4Hlv96SLJbQa'
      : 'V45XpF5xRH9FZD6eRGf1z5ZhLtGFbgLO1CHQSqXf';
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.getApiKey()
    });
  }

  private mockGetCurrency() {
    const mockCurrencyResponse = JSON.parse(`{
      "user_message": "Success",
      "data": {
        "ultimaFechaActualizacion": "2024-01-26 09:39:19",
        "valorDolar": 932.5023
      },
      "status_code": "200"
    }`);

    let obs = new Observable<CurrencyResponse>((subscriber) => {
      setTimeout(() => {
        subscriber.next(mockCurrencyResponse);
        subscriber.complete();
      }, 2000);
    });
    return obs;
  }

  getCurrency(): Observable<CurrencyResponse> {
    return this.http.get(this.api, { headers: this.getHeaders() });
    // return this.mockGetCurrency();
  }
}
